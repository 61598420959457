import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import AfterViewportJs from "@lysla/afterviewportjs";
import "jquery-match-height";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";

//#region bootstrap navbar + hamburger + body scroll lock
$(function () {
  var navbarMain = document.getElementById("navbarMain");
  if (navbarMain) {
    navbarMain.addEventListener("show.bs.collapse", function () {
      $(".navbar-toggler .hamburger").addClass("is-active");
      /* let scrollableNav = document.querySelector("#navbarMain"); */
    });
    navbarMain.addEventListener("hidden.bs.collapse", function () {
      $(".navbar-toggler .hamburger").removeClass("is-active");
      /* let scrollableNav = document.querySelector("#navbarMain"); */
    });
  }
});
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion
//#region sticky
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".sidebar", {
    stickTo: "#fixed-share-container",
    top: 175,
  });
});
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".sidebar-cart--cart", {
    stickTo: "#fixed-container-cart",
    top: 175,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          disable: true,
        },
      },
    ],
  });
});
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".sidebar-cart--checkout", {
    stickTo: "#fixed-container-checkout",
    top: 175,
  });
});
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".sidebar-cart--shipping", {
    stickTo: "#fixed-container-shipping",
    top: 175,
  });
});
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".cart-mobile", {
    stickTo: "#fixed-cart-mobile",
    stickyClass: "cart-mobile--sticked",
  });
});

document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".btn-back", {
    stickTo: ".fixed-row-esperto",
    top: 175,
  });
});
//#endregion
//#region offcavas menu mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
/* $(".close-icon").on("click", function (e) {
  $("#navbarMain").removeClass("show");
  $("body").removeClass("offcanvas-active");
}); */
//#endregion
//#region topbar
var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("topbar").style.marginTop = "0";
    /*  $(".stickybar").toggleClass("show-topbar") */
  } else {
    var height = $(".topbar").outerHeight();
    document.getElementById("topbar").style.marginTop = -height + "px";
    /*   $(".stickybar").removeClass("show-topbar"); */
  }
  prevScrollpos = currentScrollPos;
};
//#endregion
//#region read more
$(".link-more").on("click", function () {
  $(".read-more").slideToggle();
  if ($(".link-more").text() == "> Leggi Bio") {
    $(this).text("< Leggi meno");
  } else {
    $(this).text("> Leggi Bio");
  }
});
//#endregion
//#region slider
$(function () {
  $(".sliderhome").slick({
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    dots: true,
    arrows: false,
    speed: 1000,
  });
  $(".product-slider__main-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    dots: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
          asNavFor: ".product-slider__nav-slider",
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
  $(".product-slider__nav-slider").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".product-slider__main-slider",
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    arrows: false,
  });
  $(".topbar__slider").slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    dots: false,
    arrows: false,
    speed: 1000,
  });
  $(".slider-scelti").slick({
    dots: true,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
  $(".slider-shop-button").slick({
    dots: true,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false,
          centerMode: true,
          variableWidth: true,
        },
      },
    ],
  });
  $(".slider-news").slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    arrows: false,
    speed: 1000,
  });
  $(".slider-botteghe").slick({
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    appendDots: $(".slider-botteghe-dots-container"),
    dotsClass: "slick-dots",

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
  $(".slider-ricette").slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
    arrows: false,
    appendDots: $(".slider-ricette-dots-container"),
    dotsClass: "slick-dots",
  });
  $(".slider-lightbox").slick({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 2,
    appendDots: $(".slider-lightbox-dots-container"),
    dotsClass: "slick-dots",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
  $(".slider-card-botteghe").slick({
    autoplay: false,
    autoplaySpeed: 600,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
  $(".slider-card-botteghe").mouseover(function () {
    $(this).slick("play");
  });
  $(".slider-card-botteghe").mouseout(function () {
    $(this).slick("pause");
  });
  $(".slider-single-bottega").slick({
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    dots: true,
    arrows: false,
    speed: 1000,
  });
  $(".slider-related").slick({
    dots: true,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
});
//#endregion
//#region gallery
$(function () {
    Fancybox.bind('[data-fancybox="gallery__lightgallery"]', {
        // Your custom options
    });
})

//#endregion
//#region toggle fatturazione
$(".check-fatturazione").on("click", function () {
  if ($(this).prop("checked")) {
    $(this)
      .parents(".spedizione-fatturazione")
      .find(".fatturazione-diversa")
      .fadeIn("slow");
  } else $(this).parents(".spedizione-fatturazione").find(".fatturazione-diversa").fadeOut("slow");
});
//#endregion
//#region btn cart mobile
var toppag = $(".checkout-mobile");
var pag = $(".blocca-col");

toppag.css({ position: "fixed", bottom: "0px" });

$(window).on("scroll", function () {
  var scroll = $(this).scrollTop() + $(this).height();
  pag.each(function (i, n) {
    if (scroll > $(this).offset().top) {
      toppag.eq(i).css({ position: "relative" });
      if (i > 0) {
        toppag.eq(i).css({ top: i * -50 });
      }
    }
    if (scroll < $(this).offset().top + toppag.eq(i).height()) {
      toppag.eq(i).css({ position: "fixed", top: "" });
    }
  });
});
//#endregion
